/* eslint-disable no-unused-vars */ /* eslint-disable no-unused-vars */
<template>
  <el-dialog @close="close('ruleForm')" :visible="dialogVisible" :show-close="false" width="1200px" @open="openDialog()">
    <div slot="title" class="left_top">
      <div class="left_icon">
        <span></span>
        <span>新建合同</span>
      </div>
      <div class="right_icon">
        <span></span>
      </div>
    </div>
    <el-form label-position="top" width="600px" :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm"
      label-width="80px">
      <el-form-item label="合同名称" prop="name">
        <el-input v-model="ruleForm.name" placeholder="请输入合同名称"></el-input>
      </el-form-item>

      <el-form-item label="签署截止日期" prop="signDeadline">
        <el-date-picker type="date" placeholder="选择日期" :picker-options="pickerOptions" :editable="false"
          :clearable="false" v-model="ruleForm.signDeadline" style="width: 100%" value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-form-item>

      <!-- <el-form-item label="签署流程id" prop="flowId">
        <el-input v-model="ruleForm.flowId"></el-input>
      </el-form-item>

      <el-form-item label="签署状态" prop="state">
        <el-checkbox-group v-model="ruleForm.state">
          <el-checkbox label="未开始"></el-checkbox>

          <el-checkbox label="已开始"></el-checkbox>

          <el-checkbox label="已完成"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>-->

      <el-form-item label="合同分类" prop="type">
        <el-select v-model="ruleForm.type" placeholder="请选择合同分类" style="width: 100%" clearable>
          <el-option label="劳动合同" value="1"></el-option>
          <el-option label="临时劳动合同" value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="templateContent">

        <span slot="label">合同模板名称 </span>
        <!-- <span style="color:red;font-size: 12px;">(未指定带盖章的模板不可选择)</span> -->
        <el-select v-model="ruleForm.templateContent" placeholder="请选择合同模板" style="width: 100%" clearable
          @change="templateChange">
          <el-option v-for="item in templateOptions" :disabled="item.isShow" :label="item.name" :key="item.code"
            :value="item.code">
            <div style="display:flex;align-items:center;justify-content:space-between">
              <span style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;">{{ item.name }}</span> <span
                style="float: right">{{ item.isShow ? '未指定盖章位置' : ''
                }}</span>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <transition name="slide-fade">
        <div v-if="templeteObject" style="flex-grow: 1">
          <Divider :title="'模板参数配置'">
            <!-- <span slot="right">
              <el-button type="text" v-if="!previewDisable" style="color: grey" size="mini">预览</el-button>
              <el-button type="text" @click="prviewTemplate" v-if="previewDisable" size="mini">预览</el-button>
            </span>-->
          </Divider>
          <el-form-item :label="
            item.label === '甲方名称'
              ? '甲方（当前企业）签署人'
              : item.label === '乙方名称'
                ? '乙方（员工）签署人'
                : item.label
          " v-for="(item, i) in templeteObject.fields" :key="i" style="width: 100%">
            <el-input v-model="item.value" :placeholder="item.label" v-if="item.type === 'text'" @input="inputChange">
            </el-input>

            <div v-if="item.type === 'subject'">
              <SelectSubject v-model="item.value"></SelectSubject>
            </div>

            <el-input type="number" v-model="item.value" :placeholder="item.label" v-if="item.type === 'number'"
              @input="inputChange"></el-input>

            <el-input v-model="item.value" :placeholder="item.label" v-if="item.type === 'defined'"
              @input="inputChange"></el-input>

            <el-date-picker style="width: 100%" v-if="item.type === 'time'" v-model="item.value" type="date"
              :placeholder="item.label" value-format="yyyy-MM-dd HH:mm:ss" @input="inputChange"></el-date-picker>
          </el-form-item>
        </div>
      </transition>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="primary"  :loading="btnShow" @click="submitForm('ruleForm')">确 定</el-button>
      <el-button @click="close('ruleForm')">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import * as _ from 'lodash';
import { api } from '/src/api/base';
import { ContractTemplate } from './contractParse';
//新增接口
export const ContractAdd = api()('contract.add.json');
export const EnterpriseAdminInfo = api('')(
  'enterprise.admin.info.json'
);
//查询详情接口
export const ContractDetail = api()('contract.describe.json');
//编辑接口
export const ContractEdit = api()('contract.edit.json');
//删除接口
export const ContractDel = api()('contract.del.json');
// 合同模板列表
export const contractTemplateList = api()('contractTemplate.list.json');
// 合同模板详情
export const contractTemplateDescribe = api()('contractTemplate.describe.json');
//删除接口
export const ContractTemplateView = api()('contractTemplate.view.json');
// export const EnterpriseList = api()("enterprise.list.json");
// export const UserList = api()("user.list.json");
export const UserSimpleList = api()('user.simple.list.json');
export const EnterpriseSimpleList = api()('enterprise.simple.list.json');

export default {
  components: {
    Divider: () => import('/src/components/divider.vue'),
    SelectSubject: () => import('./selectSubject.vue')
    //  Upload: () => import("/src/components/upload.vue")
  },
  data () {
    return {
      pickerOptions: {                                 //禁用当前日期之前的日期
        disabledDate (time) {
          //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      enterpriseInfo: {},
      ruleForm: {
        // code: "",
        name: '',
        signDeadline: '',
        // flowId: "",
        // state: "",
        type: '',
        // signerAccounts: "",
        // signerNames: "",
        // subjectAccounts: "",
        // subjectNames: "",
        // subjectTypes: ""
        // filePaths: "",
        // esignFileIds: "",
        // creatorAccount: "",
        // creatorName: "",
        // config: "",
        templateContent: ''
      },
      rules: {
        // code: [],
        name: [{ required: true, message: '请输入合同名称', trigger: 'blur' }],
        signDeadline: [
          { required: true, message: '请选择签署截止日期', trigger: 'blur' }
        ],
        // flowId: [],
        // state: [],
        type: [{ required: true, message: '请选择合同分类', trigger: 'change' }],
        templateContent: [{ required: true, message: '请选择合同模板', trigger: 'change' }]
        // signerAccounts: [],
        // signerNames: [],
        // subjectAccounts: [],
        // subjectNames: [],
        // subjectTypes: [],
        // filePaths: [],

        // esignFileIds: [],

        // creatorAccount: [],

        // creatorName: [],

        // config: []
      },
      templateOptions: [],
      templateList: [],
      tempTemplate: '',
      previewDisable: false,
      // options: [],
      value: '',
      templatCode: '',
      userList: [],
      focusSubject: null,
      templeteObject: null,
      btnShow: false
    };
  },
  props: {
    dialogVisible: Boolean
  },
  created () {
    this.getContractTemplateList();
    this.getEnterpriseAdminInfo()
  },
  // computed: {
  //   pickerOptions ({ $props }) {
  //     return {
  //       disabledDate (time) {
  //         const date1 = new Date($props.selectableDate);
  //         const date2 = new Date(new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).substr(-2) + '-' + ('0' + (new Date().getDate())).substr(-2));
  //         const diff = (date2 - date1) + 60 * 60 * 24 * 1000;

  //         return (time.getTime() < Date.now() - diff) || (Date.now() < time.getTime());
  //       }
  //     };
  //   }

  // },
  methods: {
    async getEnterpriseAdminInfo () {
      const enterprises = await EnterpriseAdminInfo({
        code: localStorage.getItem('enterprise')
      });
      this.enterpriseInfo = enterprises
    },
    async getSubjectOptions (keywords) {

      if (this.focusSubject.subjectType === '1') {
        const enterprises = await EnterpriseSimpleList({
          pageNumber: 1,
          pageSize: 1000,
          keywords,
          authenticateState: '3'
        });
        this.focusSubject.subjectOptions = enterprises.list.map(it => {
          return {
            label: it.name,
            value: it.code
          };
        });
      }
      if (this.focusSubject.subjectType === '2') {
        const users = await UserSimpleList({
          pageNumber: 1,
          pageSize: 1000,
          keywords
        });
        this.focusSubject.subjectOptions = users.list.map(it => {
          return {
            label: it.name,
            value: it.code
          };
        });
      }
    },
    async setFocusSubject (item) {
      this.focusSubject = item;
      console.log(item);
    },
    fieldChange (value, item) {
      console.log(value, item);
    },
    async getContractTemplateList () {
      const res = await contractTemplateList({
        pageNumber: 1,
        pageSize: 1000
      });
      // console.log(res.list);
      this.templateOptions = res.list.map((it) => {
        if (it.config === '[]') {
          it.isShow = true
        }
        else {
          it.isShow = false
        }
        return it
      });
      console.log(this.templateOptions);
    },
    async templateChange (val) {
      const result = await contractTemplateDescribe({ code: val });
      this.templeteObject = new ContractTemplate(result);

      // console.log(this.templeteObject.fields, 'this.templeteObject.fields');

      // const sort = ["甲方名称", "乙方名称", "丙方名称", "丁方名称", "戊方名称"];
      // this.templatCode = val;
      // const result = await contractTemplateDescribe({ code: this.templatCode });
      // this.tempTemplate = result.content;
      // if (this.tempTemplate) {
      //   const list = this.contractTemplateField(this.tempTemplate);
      //   console.log(list, 11);
      //   this.templateList = list.map(item => {
      //     return {
      //       ...item,
      //       value: "",
      //       ...(item.type === "subject"
      //         ? {
      //             subjectOptios: [],
      //             subjectType: "1"
      //           }
      //         : {})
      //     };
      //   });
      // } else {
      //   this.templateList = [];
      // }
      // this.templateList = this.templateList.sort((a, b) => {
      //   let aindex = sort.findIndex(it => it === a.label);
      //   let bindex = sort.findIndex(it => it === b.label);
      //   if (aindex == -1) aindex = Infinity;
      //   if (bindex == -1) bindex = Infinity;
      //   return aindex - bindex;
      // });
    },
    openDialog(){
         this.btnShow = false
    },
    submitForm (formName) {

      let templeteJson = {};
      if (this.templeteObject) {
        templeteJson = {
          ...templeteJson,
          ...this.templeteObject.getSubjectFields(),
          content: this.templeteObject.getReplaceContent(),
          config: this.templeteObject.getReplaceConfig()
        };
      }
      // console.log(this.templeteObject.fields.map(it => it.value), this.templeteObject.fields);
      // console.log(_.orderBy(this.templeteObject.fields.filter(n => n.type === "subject"), n => n.value.subjectType).map(n => n.value.subjectName));

      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.btnShow = true
          const res = await ContractAdd({
            name: this.ruleForm.name,
            signDeadline: this.ruleForm.signDeadline,
            type: this.ruleForm.type,
            ...templeteJson,
            ...this.templeteObject ? {
              subjectAccounts: _.orderBy(this.templeteObject.fields.filter(n => n.type === 'subject'), n => n.value.subjectType).map(n => n.value.subjectAccount).join(','),
              subjectNames: _.orderBy(this.templeteObject.fields.filter(n => n.type === 'subject'), n => n.value.subjectType).map(n => n.value.subjectName).join(','),
              signerAccounts: this.templeteObject.fields.filter(n => typeof n.value === 'object').map(it => it.value).map((_it, i) => i ? _it.subjectAccount : this.enterpriseInfo.admin.code).filter(n => n).join(','),
              signerNames: this.templeteObject.fields.filter(n => typeof n.value === 'object').map(it => it.value).map((_it, i) => i ? _it.subjectName : this.enterpriseInfo.admin.name).filter(n => n).join(','),
              subjectTypes: this.templeteObject.fields.map(it => it.value).map(_it => _it.subjectType).filter(n => n).join(','),
            } : {},
            creatorAccount: window.localStorage.getItem('code'),
            creatorName: window.localStorage.getItem('name')
          });
          
          console.log(res);
          this.$message.success('新建合同成功!');
          this.closeDialog();
          this.$router.push({
            path: '/system/contract/list'
            // query: res
          });

        } else {
          return false;
        }
        this.btnShow = false
      });
    },
    close (formName) {
      this.$refs[formName].resetFields();
      this.templeteObject = null
      this.closeDialog();
    },
    closeDialog () {
      this.$emit('update:dialogVisible', false);
      this.$emit('close', false);
    },
    async getList (keywords) {
      const users = await UserSimpleList({
        pageNumber: 1,
        pageSize: 1000,
        keywords
      });
      const userList = users.list
        .filter(it => it.name && it.isAuthenticated)
        .map(it => {
          return {
            type: '1',
            ...it
          };
        });
      const enterprises = await EnterpriseSimpleList({
        pageNumber: 1,
        pageSize: 1000,
        keywords
      });
      const enterpriseList = enterprises.list
        .filter(it => it.name && it.authenticateState === '3')
        .map(it => {
          return {
            type: '2',
            ...it
          };
        });
      this.userList = [...userList, ...enterpriseList];
    },
    // 获取合同模板参数列表
    contractTemplateField (content) {
      const str = (
        content.match(
          /<input\s+type="button"\s+value="\[[A-Za-z0-9_\-\u4e00-\u9fa5]+\]"\s+data-type="[a-zA-Z0-9]+"\s+data-label="[A-Za-z0-9_\-\u4e00-\u9fa5]+"\s*\/>/g
        ) || []
      ).join('');
      const reg = /[a-z\-A-Z]+="[A-Za-z0-9_\-\u4e00-\u9fa5]+"/g;
      let result;
      const list = [];
      while ((result = reg.exec(str))) {
        if (result) {
          result = result.toString();
          if (/type="button"/.test(result)) {
            list.push({});
          } else {
            result = result
              .toString()
              .replace(/data-|"/g, '')
              .split('=');
            list[list.length - 1][result[0]] = result[1];
          }
        }
      }
      return _.uniqWith(list, _.isEqual);
    },
    // 替换合同模板参数
    contractTemplateValue () {
      let tmp = this.tempTemplate;
      // console.log(this.templateList, this.tempTemplate);
      this.templateList.forEach(it => {
        const reg = new RegExp(`\\[${it.label}\\]`, 'g');
        tmp = tmp.replace(reg, it.value.split('@')[0]);
      });
      return tmp;
    },
    // 替换合同签署位置参数
    contractFieldValue (config) {
      let fields = config;
      this.templateList.forEach(it => {
        const reg = new RegExp(`"subjectAccount":"\\[${it.label}\\]`, 'g');
        const reg1 = new RegExp(`"subjectName":"\\[${it.label}\\]`, 'g');
        fields = fields
          .replace(reg, `"subjectAccount":"${it.value.split('@')[1]}`)
          .replace(reg1, `"subjectName":"${it.value.split('@')[0]}`);
      });
      return fields;
    },
    // 模板预览
    async prviewTemplate () {
      const temporaryTemplate = this.contractTemplateValue();
      const res = await ContractTemplateView({
        content: temporaryTemplate
      });
      window.open(res);
    },
    inputChange () {
      console.log(this.templateList.every(it => it.value));
      this.previewDisable = this.templateList.every(it => it.value);

    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}

.left_top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left_icon {
    display: flex;
    align-items: center;

    span:nth-child(1) {
      padding: 8px 4px;
      background: #4977fc;
      margin-right: 10px;
    }

    span:nth-child(2) {
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
</style>
