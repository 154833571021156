import * as _ from 'lodash';
export class ContractTemplate {
  content = '';
  config = '';
  fields = [];
  constructor(templateObject) {
    this.content = templateObject.content;
    this.config = templateObject.config;
    this.getFieldsFromTemplate();
  }
  getFieldsFromTemplate () {
    // const sort = ["甲方名称", "乙方名称", "丙方名称", "丁方名称", "戊方名称"];
    const str = (
      this.content.match(
        /<input\s+type="button"\s+value="\[[A-Za-z0-9_\-\u4e00-\u9fa5]+\]"\s+data-type="[a-zA-Z0-9]+"\s+data-label="[A-Za-z0-9_\-\u4e00-\u9fa5]+"\s*\/>/g
      ) || []
    ).join('');
    const reg = /[a-z\-A-Z]+="[A-Za-z0-9_\-\u4e00-\u9fa5]+"/g;
 
    let result;
    const list = [];
    while ((result = reg.exec(str))) {
      if (result) {
        result = result.toString();
        if (/type="button"/.test(result)) {
          list.push({
            value: ''
          });
        } else {
          result = result
            .toString()
            .replace(/data-|"/g, '')
            .split('=');
          list[list.length - 1][result[0]] = result[1];
        }
      }
    }
    this.fields = _.uniqWith(list, _.isEqual).map(it => {
      if (it.type === 'subject') {
        it.value = {
          subjectType: it.label==='甲方名称'?'1':'2',
          subjectAccount: '',
          subjectName: ''
        };
      }
      return it;
    });
    
  }
  getReplaceConfig () {
    let str = this.config;
    console.log(this.config);
    this.fields.forEach(it => {
      if (it.type === 'subject') {
        const reg = new RegExp(`"subjectAccount":"\\[${it.label}\\]`, 'g');
        const reg1 = new RegExp(`"subjectName":"\\[${it.label}\\]`, 'g');
        str = str
          .replace(reg, `"subjectAccount":"${it.value.subjectAccount}`)
          .replace(reg1, `"subjectName":"${it.value.subjectName}`);
      }
    });
    return str;
  
  }
  getReplaceContent () {
    let str = this.content;
    this.fields.forEach(it => {
      if (it.type === 'subject') {
        const reg = new RegExp(`\\[${it.label}\\]`, 'g');
        str = str.replace(reg, it.value.subjectName);
      } else {
        const reg = new RegExp(`\\[${it.label}\\]`, 'g');
        str = str.replace(reg, it.value);
      }
    });
    return str;
  }
  getSubjectFields () {
    const list = this.fields.filter(it => it.type === 'subject');
    return {
      subjectTypes: list.map(it => it.value.subjectType).join(','),
      subjectAccounts: list.map(it => it.value.subjectAccount).join(',')
    };
  }
}
